
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import Formatter from '@/utils/Formatter.js'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  components: {
    PDEIcon,
  },
  inheritAttrs: false,
  props: {
    autoComplete: {
      type: Boolean,
      default: true,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        [
          'date',
          'email',
          'number',
          'password',
          'tel',
          'text',
        ].includes(value),
    },
    telFormat: {
      type: String,
      default: undefined,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    // Use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validate needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')

    // We don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      value,
    } = useField(name, undefined, { syncVModel: true })

    return {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      value,
    }
  },

  data: () => ({
    showPassword: false,
  }),

  computed: {
    hasError() {
      return !!(this.errorMessage && this.meta.touched)
    },

    inputClasses() {
      return [
        this.hasError ? 'border-error-900' : 'border-pureblack-20',
        this.isPassword ? 'pr-48 [&::-ms-reveal]:hidden' : '',
      ]
    },

    inputType() {
      if (this.isPassword) return this.showPassword ? 'text' : 'password'

      return this.type
    },

    isPassword() {
      return this.type === 'password'
    },
  },

  mounted() {
    // force formatting on mount for initial values
    this.handleChange(this.formatValue(this.value))

    if (this.autoFocus) {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },

  methods: {
    formatByName(value) {
      if (this.name === 'ssn') return Formatter.ssn(value)

      return value
    },

    formatByType(value) {
      if (this.type === 'number') return this.formatNumber(value)
      if (this.type === 'tel') return Formatter.phone(value, this.telFormat)
      if (this.type === 'text') return this.formatText(value)

      return value
    },

    formatText(value) {
      if (this.$attrs.maxlength) return Formatter.maxlength(value, this.$attrs.maxlength)

      return value
    },

    formatNumber(value) {
      let formattedValue = Formatter.number(value)

      if (this.$attrs.maxlength) {
        formattedValue = Formatter.maxlength(formattedValue, this.$attrs.maxlength)
      }

      return formattedValue
    },

    formatValue(value) {
      if (!value) return value
      let formattedValue = value

      formattedValue = this.formatByName(formattedValue)
      formattedValue = this.formatByType(formattedValue)

      return formattedValue
    },

    handleInput({ target: { value } }) {
      this.handleChange(this.formatValue(value))
      this.$forceUpdate()
    },

    // Toggle revealing password
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword

      const cursorPosition = this.$refs.input.selectionStart

      this.$nextTick(() => {
        this.$refs.input.focus()
        this.$refs.input.setSelectionRange(
          cursorPosition,
          cursorPosition
        )
      })
    },
  },
}
